<template>
  <!-- <div style="background:blue; height:100vh;" class="fill-height">
      <h1>Siti WEB</h1>
  </div> -->

  <!-- <div>
      <link href="https://fonts.googleapis.com/css?family=Josefin+Sans" rel="stylesheet"> -->

  <div class="container-fluid landingpage">
    <div class="background">
      <ul class="cb-slideshow-website">
        <div>
          <span>Image 01</span>
          <div></div>
        </div>
        <div>
          <span>Image 02</span>
          <div></div>
        </div>
        <div>
          <span>Image 03</span>
          <div></div>
        </div>
        <div>
          <span>Image 04</span>
          <div></div>
        </div>
      </ul>

      <div class="cube"></div>
      <div class="cube"></div>
      <div class="cube"></div>
      <div class="cube"></div>
      <div class="cube"></div>
      <div class="cube"></div>
      <div class="cube"></div>
      <div class="cube"></div>
      <div class="cube"></div>
    </div>

    <div style="position: absolute; top: 0%; width: 100vw; height: 100vh">
      <v-row justify="center" style="width: 100vw; margin: auto" class="dark-bottom-right">

        <v-col cols="12" sm="12" md="10" class="">
          <v-row no-gutters>
            <v-col cols="12" sm="5" class="text-left">
              <!-- <div v-bind:style="{ 'height': main_heading_height }"> -->
              <div>
                <h1 data-aos="fade-in" data-aos-duration="2000" class="main-heading"
                  v-bind:style="{ 'font-size': main_heading_size }">
                  <span data-aos="fade-in" data-aos-delay="300" data-aos-duration="2000">Il</span>
                  <span data-aos="fade-in" data-aos-delay="600" data-aos-duration="2000" class="glove-red "> tuo
                    sito</span>
                  <span data-aos="fade-in" data-aos-delay="900" data-aos-duration="2000"> web.</span>
                  <p>
                    <span data-aos="fade-in" data-aos-delay="1500" data-aos-duration="2000">Il</span>
                    <span data-aos="fade-in" data-aos-delay="1800" data-aos-duration="2000"> tuo</span>
                    <span data-aos="fade-in" data-aos-delay="2100" data-aos-duration="2000"> biglietto</span>
                    <span data-aos="fade-in" data-aos-delay="2400" data-aos-duration="2000"> da</span>
                    <span data-aos="fade-in" data-aos-delay="2700" data-aos-duration="2000"> visita.</span>
                    <!-- Il tuo biglietto da visita. -->
                  </p>
                </h1>
              </div>

              <!-- v-bind:style="{ 'font-size': main_subheading_size }" -->
              <!-- v-bind:style="{ 'font-size': main_subheading_size, ' height': main_subheading_height }" -->
              <!-- <div v-bind:style="{ 'height': main_subheading_height }"> -->
              <div>
                <h2 v-if="show_subheader" class="mt-6 mb-6" v-bind:style="{ 'font-size': main_subheading_size }"
                  style="line-height:1">
                  <span data-aos="fade-in" data-aos-delay="600" data-aos-duration="2000">
                    Sfrutta
                  </span>
                  <span data-aos="fade-in" data-aos-delay="900" data-aos-duration="2000">
                    la presenza
                  </span>
                  <span data-aos="fade-in" data-aos-delay="1200" data-aos-duration="2000">
                    online
                  </span>
                  <span data-aos="fade-in" data-aos-delay="1500" data-aos-duration="2000">
                    per
                  </span>
                  <span data-aos="fade-in" data-aos-delay="1800" data-aos-duration="2000" class="mt-6">
                    far </span>
                  <span data-aos="fade-in" data-aos-delay="2100" class="glove-acquamarine">crescere</span>
                  <span data-aos="fade-in" data-aos-delay="2400">
                    il tuo
                  </span>
                  <span data-aos="fade-in" data-aos-delay="2700" class="glove-red">
                    business
                  </span>.
                  </span>
                </h2>
              </div>
            </v-col>
            <v-col cols="12" sm="7" class="text-center d-flex justify-center">
              <div class="fill-height d-flex align-end" style="padding-bottom: 2%"
                v-bind:style="{ 'padding-left': padding_macbook }">
                <img data-aos="fade-right" :src="require('@/assets/imgs/pages/landings/macbook_02.png')"
                  alt="MacBook Website preview" style="height: auto" v-bind:style="{
                    width: width_macbook,
                  }" cover />
              </div>
              <div class="fill-height d-flex align-end" v-bind:style="{ 'margin-left': margin_iphone }">
                <img data-aos="fade-left" data-aos-delay="500"
                  :src="require('@/assets/imgs/pages/landings/iphone_02.png')" alt="Iphone Website preview"
                  style="height: auto" v-bind:style="{
                    width: width_iphone,
                  }" />
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!--  -->
      <!--  -->
      <!--  -->
      <!--  -->
      <div data-aos="fade-in" data-aos-delay="2000" data-aos-duration="3000">
        <h2 style="padding-top:5vh;" class="text-center white--text mt-12"
          v-bind:style="{ 'height': find_more_padding_y }">Scopri come</h2>
        <div style="padding-top:5vh;padding-bottom:20vh" class="arrow-down mb-12">
          <div class="chevron"></div>
          <div class="chevron"></div>
          <div class="chevron"></div>
        </div>
      </div>
      <!--  -->
      <!--  -->
      <!--  -->
      <!--  -->
      <div data-aos="fade-right">

        <v-row no-gutters justify="center" style="width: 100vw; margin: auto;margin-top:10vh;"
          class="dark-top-fade-to-black">

          <v-col style="height:20vh" cols="12" sm="12" md="10"
            class="text-center d-flex align-end justify-center flex-row">

            <v-img style="width:100%;max-width:800px" :src="require('@/assets/imgs/pages/landings/crystal.png')"
              alt="Crystal preview" contain>
            </v-img>

          </v-col>
        </v-row>

        <v-row no-gutters justify="center" style="width: 100vw; margin: auto" class="black bottom-white">

          <v-col cols="12" sm="12" md="10" class="text-center d-flex align-center justify-center flex-column">
            <div class="text-center" style=" background: linear-gradient(0deg, rgba(0,0,0,1) 92%, rgba(0,0,0,0) 98%); padding:1rem;padding-top:2rem;
" v-bind:style="{ 'font-size': main_subheading_size }">

              <span class="white--text main-shadow-white main-bold">Metti in mostra i tuoi servizi e i tuoi
                prodotti.</span>
              <p class="white--text main-shadow ">
                Con il <span class="glove-acquamarine main-shadow-white">prestigio</span> che si meritano.</p>
            </div>

            <!-- <div style="max-width:400px; border-bottom-style: solid; border-bottom-color: white;" class="text-center"> -->
            <div class="box-description-text">
              <p class="box-description-text">
                Inserisci i tuoi prodotti o i tuoi servizi
                all'interno di una vetrina
                interattiva dove i visitatori possano essere attratti, ricevere maggiori informazioni ed essere
                trasformati in clienti
              </p>
              <!-- </div> -->
            </div>

          </v-col>
        </v-row>
      </div>
      <!--  -->
      <!--  -->
      <!--  -->
      <!--  -->
      <div style="padding-top:20vh;padding-bottom:10vh" class="arrow-down mb-12">
        <div class="chevron"></div>
        <div class="chevron"></div>
        <div class="chevron"></div>
      </div>
      <!--  -->
      <!--  -->
      <!--  -->
      <!--  -->

      <div data-aos="fade-right">

        <v-row no-gutters justify="center" style="width: 100vw; margin: auto;margin-top:10vh;"
          class="dark-top-fade-to-black">

          <v-col style="height:20vh" cols="12" sm="12" md="10"
            class="text-center d-flex align-end justify-center flex-row">

            <!-- <span class="white--text"
              style="position:relative;top:1rem; transform: rotate(355deg);z-index:2; font-weight:bold;    text-shadow: 4px 4px 3px #6a6a6a;"
              v-bind:style="{ 'font-size': main_heading_size }">
              Distinguiti</span> -->
            <v-img style="width:100%;max-width:800px" :src="require('@/assets/imgs/pages/landings/skyline.png')"
              alt="Skyline preview" cover>
            </v-img>

          </v-col>
        </v-row>

        <v-row no-gutters justify="center" style="width: 100vw; margin: auto" class="black bottom-white">

          <v-col cols="12" sm="12" md="10" class="text-center d-flex align-center justify-center flex-column">
            <div style=" background: linear-gradient(0deg, rgba(0,0,0,1) 92%, rgba(0,0,0,0) 98%); padding:1rem;padding-top:2rem;
" v-bind:style="{ 'font-size': main_subheading_size }">

              <span class="white--text main-shadow-white main-bold">Rendi unica la tua presenza online.</span>
              <!-- <p class="white--text main-shadow ">
                Dominio, web e indirizzi email.
              </p> -->
              <p class="white--text main-shadow ">
                Per un'impronta <span class="glove-acquamarine main-shadow-white">personale</span> e <span
                  class="glove-red main-shadow-white">professionale</span>.
              </p>
            </div>

            <div class="box-description-text">
              <p class="box-description-text">Un dominio internet di secondo livello rende la tua presenza online più
                professionale.</p>
              <p class="mt-0 pt-0 box-description-text">Utilizza un dominio internet tuo sia per il sito web che per le
                tue comunicazioni via email.</p>
            </div>

          </v-col>
        </v-row>
      </div>
      <!--  -->
      <!--  -->
      <!--  -->
      <!--  -->
      <div style="padding-top:20vh;padding-bottom:20vh" class="arrow-down mb-12">
        <div class="chevron"></div>
        <div class="chevron"></div>
        <div class="chevron"></div>
      </div>
      <!--  -->
      <!--  -->
      <!--  -->
      <div data-aos="fade-right">
        <v-row no-gutters justify="center" style="width: 100vw; margin: auto;margin-top:10vh;"
          class="dark-top-fade-to-black">
          <v-col style="height:20vh" cols="12" sm="12" md="10"
            class="text-center d-flex align-end justify-center flex-row">
            <v-img v-if="$vuetify.breakpoint.smAndDown" style="width:100%;max-width:800px"
              :src="require('@/assets/imgs/pages/landings/manuals_01.png')" alt="Handshake preview" contain>
            </v-img>
            <v-img v-if="!$vuetify.breakpoint.smAndDown" style="width:100%;max-width:800px"
              :src="require('@/assets/imgs/pages/landings/manuals_01.png')" alt="Handshake preview" contain>
            </v-img>
          </v-col>
        </v-row>
        <v-row no-gutters justify="center" style="width: 100vw; margin: auto" class="black bottom-white">
          <v-col cols="12" sm="12" md="10" class="text-center d-flex align-center justify-center flex-column">
            <div style=" background: linear-gradient(0deg, rgba(0,0,0,1) 92%, rgba(0,0,0,0) 98%); padding:1rem;padding-top:2rem; 
" v-bind:style="{ 'font-size': main_subheading_size }">



              <p class="white--text main-shadow-white main-bold"><span
                  class="white--text main-shadow-white main-bold"></span>Rendi le informazioni <span
                  class="glove-acquamarine main-shadow-white">accessibili</span>.</p>
              <span class="white--text main-shadow-white main-shadow">Offri servizi<span
                  class="glove-red main-shadow-white"> avanzati</span>.</span>
            </div>

            <div class="box-description-text">
              <p class="box-description-text">Fornisci ai tuoi clienti l'accesso ad informazioni aggiuntive, guide e
                manuali per i tuoi prodotti.</p>
              <!-- <p class="box-description-text">Rendi facilmente accessibili le informazioni sui tuoi prodotti attraverso
                l'uso ad esempio di Qrcode o tencologie NFC.</p> -->
            </div>

          </v-col>
        </v-row>
      </div>
      <!--  -->
      <!--  -->
      <!--  -->
      <!--  -->
      <div style="padding-top:20vh;padding-bottom:20vh" class="arrow-down mb-12">
        <div class="chevron"></div>
        <div class="chevron"></div>
        <div class="chevron"></div>
      </div>
      <!--  -->
      <!--  -->
      <!--  -->
      <div data-aos="fade-right">
        <v-row no-gutters justify="center" style="width: 100vw; margin: auto;margin-top:10vh;"
          class="dark-top-fade-to-black">
          <v-col style="height:20vh" cols="12" sm="12" md="10"
            class="text-center d-flex align-end justify-center flex-row">
            <v-img v-if="$vuetify.breakpoint.smAndDown" style="width:100%;max-width:800px"
              :src="require('@/assets/imgs/pages/landings/handshake_01.png')" alt="Handshake preview" contain>
            </v-img>
            <v-img v-if="!$vuetify.breakpoint.smAndDown" style="width:100%;max-width:800px"
              :src="require('@/assets/imgs/pages/landings/handshake_02.png')" alt="Handshake preview" contain>
            </v-img>
          </v-col>
        </v-row>
        <v-row no-gutters justify="center" style="width: 100vw; margin: auto" class="black bottom-white">
          <v-col cols="12" sm="12" md="10" class="text-center d-flex align-center justify-center flex-column">
            <div style=" background: linear-gradient(0deg, rgba(0,0,0,1) 92%, rgba(0,0,0,0) 98%); padding:1rem;padding-top:2rem; 
" v-bind:style="{ 'font-size': main_subheading_size }">
              <span class="white--text main-shadow-white main-bold">Ottieni nuovi <span
                  class="glove-red main-shadow-white">contatti</span>.</span>
              <p class="white--text main-shadow-white main-shadow"><span
                  class="white--text main-shadow-white main-bold"></span>Genera nuovi <span
                  class="glove-acquamarine main-shadow-white">clienti</span>.</p>
            </div>

            <div class="box-description-text">
              <p class="box-description-text">Integra all'interno del tuo sito web un modulo, semplice ed immediato, per
                la raccolta delle informazioni di contatto di nuovi potenziali clienti.</p>
            </div>

          </v-col>
        </v-row>
      </div>
      <!--  -->
      <!--  -->
      <!--  -->
      <!--  -->
      <div style="padding-top:20vh;padding-bottom:20vh" class="arrow-down mb-12">
        <div class="chevron"></div>
        <div class="chevron"></div>
        <div class="chevron"></div>
      </div>
      <!--  -->
      <!--  -->
      <!--  -->
      <div data-aos="fade-right">
        <v-row no-gutters justify="center" style="width: 100vw; margin: auto;margin-top:10vh; "
          :style="padding_bottom_contact">
          <v-col cols="12" sm="10" md="6" lg=6 xl=3>
            <!-- <v-card class="text-center d-flex justify-center align-center flex-column"> -->
            <v-card v-if="!messageSent" class="mx-2 text-center pb-12">
              <div class="d-flex justify-center flex-column align-center" style="width:100%">
                <h1 class="my-12" style="width:75%">Interessante!
                </h1>
                <h2 class="black--text mb-12" style="width:75%">Scopri di più sulle possibilità e vantaggi di una
                  presenza online professionale</h2>
              </div>
              <div v-if="messageSent == false" class="mx-12">
                <v-text-field label="Nome" v-model="nome" :error-messages="err.nome">
                </v-text-field>

                <v-text-field label="Email" v-model="email" :error-messages="err.email">
                </v-text-field>

                <v-checkbox color="secondary" v-model="agreement" label="" dense class="mt-6"
                  :error-messages="err.agreement">
                  <template v-slot:label>
                    <div>
                      <span class="black--text" style="font-size: 0.8rem">
                        Accetto il trattamento delle informazioni e desidero essere contattato.</span>
                    </div>
                  </template>
                </v-checkbox>
                <v-btn class="mt-6 mb-6 py-8" block outlined @click="validate()" :loading="disableSend">
                  Invia
                </v-btn>
              </div>
            </v-card>
            <v-card v-if="messageSent" class="mx-2 text-center pb-12">
              <v-col cols="12">
                <span>
                  <h1 class="black--text">Grazie!</h1>
                </span>
                <br />
                <h2 class="black--text">Il tuo messaggio è stato inviato.</h2>
                <br />
                <br />
                <h3 class="black--text">Verrai contattato al più presto.</h3>
              </v-col>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>





  </div>
</template>

<script>
// import "@/styles/rotating_squares.css";
import "@/styles/landing_fonts.css";
import "@/styles/carousel.css";
import "@/styles/devices.css";
import "@/styles/text_effect.css";
import "@/styles/scroll_down.css";
import "@/styles/background_animation_landing_website.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { firebaseFunctions } from "@/plugins/firebase";
import { gtag } from "@/gtag";

function classToggle() {
  var el = document.querySelector(".icon-cards__content");
  el.classList.toggle("step-animation");
}

export default {
  metaInfo: {
    title: "Sviluppo siti Web",
    // titleTemplate: "%s | AVMSquare",
  },
  props: {},
  components: {},
  data: () => ({
    nome: '',
    email: '',
    topic: 'web',
    contact_type: 'Email',
    messaggio: '',
    agreement: false,
    disableSend: false,
    messageSent: false,
    err: {
      nome: '',
      email: '',
      agreement: ''
    },
    show_subheader: false,
    sending: false
  }),
  methods: {
    resetErrors() {
      this.err = {
        nome: null,
        azienda: null,
        email: null,
        telefono: null,
        contact_type: "Email",
        body: null,
        topic: null,
      };
    },
    validateEmail(email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
      }
      return false;
    },
    validate() {
      // gtag("event", "contact_form_validate", {
      //   contact_type: this.contact_type,
      //   topic: this.topic,
      // });

      this.resetErrors();
      this.disableSend = true;
      console.log("validate");
      let isValid = true;
      if (this.nome.length < 3 || this.nome.length > 20) {
        this.err.nome = "Inserisci nome valido (3-20 caratteri)";
        isValid = false;
      }

      // if (
      //   (this.isAzienda && this.azienda.length < 2) ||
      //   (this.isAzienda && this.azienda.length > 20)
      // ) {
      //   this.err.azienda = "Inserisci nome azienda valido (3-20 caratteri)";
      //   isValid = false;
      // }

      if (this.contact_type == "Email") {
        if (this.validateEmail(this.email) == false) {
          this.err.email = "Inserisci indirizzo email valido";
          isValid = false;
        }
      }

      if (!this.agreement) {
        this.err.agreement = "Necessario accettare le condizioni";
        isValid = false
      }

      // if (this.contact_type == "Telefono" || this.contact_type == "Whatsapp") {
      //   if (this.validatePhone(this.telefono) == false) {
      //     this.err.telefono = "Inserisci numero telefonico valido";
      //     isValid = false;
      //   }
      // }

      if (this.topic == null || this.topic == "") {
        this.err.topic = "Seleziona un area d'interesse";
        isValid = false;
      }

      // if (this.messaggio.length < 10 || this.messaggio.length > 255) {
      //   this.err.messaggio = "Inserisci un messaggio valido (10-255 caratteri)";
      //   isValid = false;
      // }

      if (isValid) {
        this.sendMessage();
      } else {
        this.disableSend = false;
      }
    },
    sendMessage() {
      const CF = firebaseFunctions.httpsCallable("siteContact");
      let now = new Date();
      const nowUT = now.getTime() / 1000;
      let ut_lastFeedbackPrompt = localStorage.getItem("ut_lastMessage");
      this.btn_send = "Invio in corso, attendere";
      if (nowUT - ut_lastFeedbackPrompt < 120) {
        alert("Attendi qualche minuto per inviare un nuovo messaggio");
        this.disableSend = false;
      } else {
        // analytics.logEvent("messageSent");
        gtag("event", "contact_form_sent", {
          contact_type: this.contact_type,
          topic: this.topic,
        });
        // gtag("event", "generate_lead", {});
        let payload = {
          nome: this.nome,
          isAzienda: this.isAzienda,
          azienda: this.azienda,
          email: this.email,
          telefono: this.telefono,
          contact_type: this.contact_type,
          topic: this.topic,
          messaggio: this.messaggio,
          altreComunicazioni: this.altreComunicazioni,
          ipdata: this.ipdata,
        };
        console.log(payload);
        this.disableSend = true;
        CF(payload)
          .then((result) => {
            this.result = result;
            if (result.data.status == 0) {
              this.messageSent = true;
            }
            this.$router.push({ name: 'LandingPage_Thankyou' })
            this.btn_send = "Messaggio inviato";
            console.log(result);
          })
          .catch((err) => {
            this.disableSend = false;
            this.btn_send = "Invia";
            console.log("ERR");
            console.log(err);
          });
      }
    },

  },
  mounted() {
    AOS.init();
    setTimeout(() => {
      this.show_subheader = true
    }, 2500);
    // document
    //   .querySelector("#toggle-animation")
    //   .addEventListener("click", classToggle);
  },
  created() { },
  computed: {

    padding_bottom_contact() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          console.log("xs");
          return "padding-bottom:35vh";
        case "sm":
          console.log("sm");
          return "padding-bottom:25vh";
        case "md":
          console.log("md");
          return "padding-bottom:20vh";
        case "lg":
          console.log("lg");
          return "padding-bottom:30vh";
        case "xl":
          console.log("xl");
          return "padding-bottom:30vh";
        default:
          return;
      }
      // 

    },

    find_more_padding_y() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          console.log("xs");
          return "height: 100px";
        case "sm":
          console.log("sm");
          return "3rem";
        case "md":
          console.log("md");
          return "4rem";
        case "lg":
          console.log("lg");
          return "5rem";
        case "xl":
          console.log("xl");
          return "padding-top: 100px;padding-bottom:100px;";
        default:
          return;
      }
    },

    main_heading_size() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          console.log("xs");
          return "3.2rem";
        case "sm":
          console.log("sm");
          return "3rem";
        case "md":
          console.log("md");
          return "4rem";
        case "lg":
          console.log("lg");
          return "4rem";
        case "xl":
          console.log("xl");
          return "5.5rem";
        default:
          return;
      }
    },

    main_heading_height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          console.log("xs");
          return "200px";
        case "sm":
          console.log("sm");
          return "150px";
        case "md":
          console.log("md");
          return "350px";
        case "lg":
          console.log("lg");
          return "250px";
        case "xl":
          console.log("xl");
          return "300px";
        default:
          return;
      }
    },


    main_subheading_size() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          console.log("xs");
          return "1.6rem";
        case "sm":
          console.log("sm");
          return "1.5rem";
        case "md":
          console.log("md");
          return "2rem";
        case "lg":
          console.log("lg");
          return "2rem";
        case "xl":
          console.log("xl");
          return "3rem";
        default:
          return;
      }
    },

    main_subheading_height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          console.log("xs");
          return "100px";
        case "sm":
          console.log("sm");
          return "1.5rem";
        case "md":
          console.log("md");
          return "15rem";
        case "lg":
          console.log("lg");
          return "12rem";
        case "xl":
          console.log("xl");
          return "3rem";
        default:
          return;
      }
    },


    margin_iphone() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          console.log("xs");
          return "-26vw";
        case "sm":
          console.log("sm");
          return "-14vw";
        case "md":
          console.log("md");
          return "-12vw";
        case "lg":
          console.log("lg");
          return "-7vw";
        case "xl":
          console.log("xl");
          return "-10vw";
        default:
          return;
      }
    },

    padding_macbook() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          console.log("xs");
          return "2vw";
        // return "-40vw";
        case "sm":
          console.log("sm");
          return "0";
        case "md":
          console.log("md");
          return "8vw";
        case "lg":
          console.log("lg");
          return "6vw";
        case "xl":
          console.log("xl");
          return "5vw";
        default:
          return;
      }
    },

    width_macbook() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          console.log("xs");
          return "54vw";
        case "sm":
          console.log("sm");
          return "40vw";
        case "md":
          console.log("md");
          return "35vw";
        case "lg":
          console.log("lg");
          return "22vw";
        case "xl":
          console.log("xl");
          return "24vw";
        default:
          return;
      }
    },

    width_iphone() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          console.log("xs");
          return "32vw";
        case "sm":
          console.log("sm");
          return "22vw";
        case "md":
          console.log("md");
          return "20vw";
        case "lg":
          console.log("lg");
          return "14vw";
        case "xl":
          console.log("xl");
          return "12vw";
        default:
          return;
      }
    },

    // height_iphone() {
    //   if (this.$vuetify.breakpoint.xs) {
    //     return "60vw";
    //   } else {
    //     return "45vw";
    //   }
    // },
  },
  watch: {},
};
</script>

<style scoped>
.container-fluid {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  /* background: #fff; */
}

.slide_to_right {
  position: relative;
  animation: move_to_right 0.85s infinite;
  animation-direction: normal;
}

.bottom-white {
  border-bottom-style: solid;
  border-bottom-color: white !important;
}

@keyframes move_to_right {
  0% {
    opacity: 0;
  }

  2% {
    left: 0;
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  25% {
    left: 95%;
    opacity: 1;
  }

  99% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    left: 95%;
  }
}

.slide_to_left {
  position: relative;
  animation: move_to_left 0.75s infinite;
  animation-direction: normal;
}

.box-description-text {
  color: white;
  font-size: 1rem;
  font-weight: lighter;
  max-width: 500px;
  display: block;
  padding: 2rem
}

@keyframes move_to_left {
  0% {
    opacity: 0;
  }

  2% {
    left: 95%;
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  25% {
    left: 0%;
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    left: 0%;
  }
}

.light-row {
  background: #ffffffa6;
  padding-top: 3rem;
  padding-bottom: 3rem;
  color: black;
}

.dark-bottom-right {
  background: #0000006e;
  padding-top: 3rem;
  padding-bottom: 1rem;
  color: white;
  border-radius: 0 0 23vw 0;
  border-bottom-color: #ffffff8f;
  border-bottom-style: solid;
  border-bottom-width: 15px;
  -webkit-transition: border 500ms ease-out;
  -moz-transition: border 500ms ease-out;
  -o-transition: border 500ms ease-out;
}

.dark-bottom-right:hover {
  border-bottom: 18px solid rgb(255, 255, 255);
}

/* --- */
.dark-top-right {
  background: #0000006e;
  padding-top: 3rem;
  padding-bottom: 5rem;
  color: white;
  border-radius: 0 0 23vw 0;
  border-radius: 0 23vw 0 0;
  border-top-color: #ffffff8f;
  border-top-style: solid;
  border-top-width: 15px;
  -webkit-transition: border 500ms ease-out;
  -moz-transition: border 500ms ease-out;
  -o-transition: border 500ms ease-out;
}

.dark-top-right:hover {
  border-top: 18px solid rgb(255, 255, 255);
}

/* --- */
.light-top-right {
  background: #ffffff82;
  padding-top: 3rem;
  padding-bottom: 5rem;
  color: black;
  border-radius: 0 0 23vw 0;
  border-radius: 0 23vw 0 0;
  border-top-color: #0000008f;
  border-top-style: solid;
  border-top-width: 15px;
  -webkit-transition: border 500ms ease-out;
  -moz-transition: border 500ms ease-out;
  -o-transition: border 500ms ease-out;
}

.light-top-right:hover {
  border-top: 18px solid rgb(0, 0, 0);
}

/* --- */
.light-bottom-right {
  background: #ffffff82;
  padding-top: 3rem;
  padding-bottom: 5rem;
  color: black;
  border-radius: 0 0 23vw 0;
  border-bottom-color: #0000008f;
  border-bottom-style: solid;
  border-bottom-width: 15px;
  -webkit-transition: border 500ms ease-out;
  -moz-transition: border 500ms ease-out;
  -o-transition: border 500ms ease-out;

}

.light-bottom-right:hover {
  border-bottom: 18px solid rgb(0, 0, 0);
}

/* --- */
.light-top-fade {
  padding-top: 3rem;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.51) 0%, rgba(255, 255, 255, 0.42) 75%, rgba(255, 255, 255, 0) 100%);
}

.light-bottom-fade {
  padding-top: 3rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.51) 0%, rgba(255, 255, 255, 0.42) 75%, rgba(255, 255, 255, 0) 100%);
}

/* --- */
.dark-top-fade {
  padding-top: 3rem;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.51) 0%, rgba(0, 0, 0, 0.42) 75%, rgba(255, 255, 255, 0) 100%);
}

.dark-bottom-fade {
  padding-top: 3rem;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.51) 0%, rgba(0, 0, 0, 0.42) 75%, rgba(255, 255, 255, 0) 100%);
}

/* --- */
.dark-top-fade-to-black {
  padding-top: 3rem;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.42) 75%, rgba(255, 255, 255, 0) 100%);
}


/* --- */
.black-top-fade {
  padding-top: 3rem;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 75%, rgba(255, 255, 255, 0) 100%);
}

.black-bottom-fade {
  padding-top: 3rem;
  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 75%, rgba(255, 255, 255, 0) 100%);
}

@keyframes animate_border {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.transparent-row {
  /* background: #000000a6; */
  padding-top: 3rem;
  padding-bottom: 3rem;
  color: white;
}
</style>












    <!-- <div class="computer">
                <img
                  :src="
                    require('@/assets/imgs/pages/landings/devices/mb_white2.png')
                  "
                  alt="MacBook Computer Apple"
                />
                <div id="gif" style="background-position-y: 50%"></div>
              </div> -->

    <!-- <h2>Veloci</h2>

          <table>
            <tr>
              <td>
                <v-img
                  :src="
                    require('@/assets/imgs/pages/landings/mobile_users.png')
                  "
                  alt="Icona consulenza informatica"
                  width="75"
                ></v-img>
              </td>

              <td style="width: 400px">
                <v-img
                  :src="
                    require('@/assets/imgs/pages/landings/data_transfer_left.png')
                  "
                  alt="Icona consulenza informatica"
                  max-width="30"
                  class="slide_to_left"
                  contain
                ></v-img>
              </td>

              <td>
                <v-img
                  :src="require('@/assets/imgs/pages/landings/globe.png')"
                  alt="Icona consulenza informatica"
                  width="75"
                ></v-img>
              </td>
              <td style="width: 400px">
                <v-img
                  :src="
                    require('@/assets/imgs/pages/landings/data_transfer_right.png')
                  "
                  alt="Icona consulenza informatica"
                  max-width="30"
                  class="slide_to_right"
                  contain
                ></v-img>
              </td>
              <td>
                <v-img
                  :src="
                    require('@/assets/imgs/pages/landings/mobile_users.png')
                  "
                  alt="Icona consulenza informatica"
                  width="75"
                ></v-img>
              </td>
            </tr>
          </table> -->

    <!-- <p>Accattivanti</p>
              <br />
              <h3>Il tuo messaggio deve arrivare veloce</h3>
              <p>Tutti noi navigatori del Web non sopportiamo attendere</p>

              <h2>Ultime tecnologie</h2>
              <h3>
                Adottiamo le ultime tecnologie per garantirti una presenza
                online che sia moderna e al passo coi tempi (e con i tuoi
                concorrenti)
              </h3>
              <br />
              <h2>Indicizzabili! (Fatti trovare!)</h2>
              <h3>
                Creiamo siti che siano pronti per l'indicizzazione dei motori di
                ricerca
              </h3>
              <p>
                Il traffico cosi' detto organico, è traffico gratuito e di
                qualità generato dai tuoi utenti che cercano sui motori di
                ricerca i servizi e i prodotti che tu offri.
              </p>
              <p>
                Ci impegnamo per strutturare il tuo sito secondo le regole per
                cercare farti apparire tra i primi risultati delle ricerche.
              </p>

              <h2>Metti in mostra i tuoi prodotti e servizi</h2>

              <h2>Cattura contatti</h2>

              <h2>Adattano ad ogni dispositivo</h2>

              <h2>Gestione Domini</h2>

              <h2>Email con domini personalizzati</h2>

              <v-row>
                <v-col cols="12">
                  <h2>Galleria immagini dei tuoi prodotti o lavori</h2>
                </v-col>
                <v-col cols="12" class="d-flex justify-center">
                  <div>
                    <figure class="icon-cards mt-3">
                      <div class="icon-cards__content">
                        <div
                          class="
                            icon-cards__item
                            d-flex
                            align-items-center
                            justify-content-center
                          "
                        >
                          <v-img
                            :src="
                              require('@/assets/caroselli/elettricista_01.jpeg')
                            "
                            alt="Icona consulenza informatica"
                            class=""
                            :max-width="header_img_width"
                          ></v-img>
                        </div>
                        <div
                          class="
                            icon-cards__item
                            d-flex
                            align-items-center
                            justify-content-center
                          "
                        >
                          <v-img
                            :src="
                              require('@/assets/caroselli/produzione_01.jpeg')
                            "
                            alt="Icona consulenza informatica"
                            class=""
                            :max-width="header_img_width"
                          ></v-img>
                        </div>
                        <div
                          class="
                            icon-cards__item
                            d-flex
                            align-items-center
                            justify-content-center
                          "
                        >
                          <v-img
                            :src="require('@/assets/caroselli/enoteca_01.jpeg')"
                            alt="Icona consulenza informatica"
                            class=""
                            :max-width="header_img_width"
                          ></v-img>
                        </div>
                      </div>
                    </figure>
                  </div>
                </v-col>
              </v-row>

Integra una mappa di google maps per farti trovare

              <h2>Campagne pubblicitarrie (landing page per promo)</h2> -->
    <!-- </v-card-text>
          </v-card> -->